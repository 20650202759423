
/* stylelint-disable-next-line selector-id-pattern */
#registrationModal {
  background-color: var(--modal-bg-color);

  .modal-content {
    border: 0;
    box-shadow: 0 16px 16px -12px rgba(0, 63, 51, 0.08);
  }

  .modal-close {
    @media screen and (max-width: 460px) {
      position: fixed;
      top: 10px;
      right: 10px;
      border: 0;
      background: transparent;
    }
  }

  .full-name-row {
    margin-top: calc(var(--grid-unit) * 3);
    margin-bottom: calc(var(--grid-unit) * 2);
    display: flex;

    .k-form-field__name {
      margin: 0 !important;
    }

    :first-child {
      padding-right: var(--grid-unit) !important;
    }
  }

  ::v-deep {
    .react-code-input-container {
      width: auto !important;

      input {
        border-radius: var(--grid-unit) !important;
        margin-right: calc(var(--grid-unit) * 3) !important;
        border-right: 1px solid #3B3B3B !important;
        border: 1px solid #3B3B3B !important;
        border-top-left-radius: var(--grid-unit) !important;
        border-bottom-left-radius: var(--grid-unit) !important;
        font-family: var(--sans-serif);

        @media screen and (max-width: 400px) {
          margin-right: var(--grid-unit) !important;
        }
      }
    }

    .react-code-input-container.error {
      input {
        color: var(--rust-40) !important;
        border: 1px solid var(--rust-40) !important;
      }
    }
}

  .switch-form {
    .title {
      font-weight: 500;
      text-align: center;
      color: var(--gray-80);
      margin-top: calc(var(--grid-unit) * 3);
    }

    .action {
      font-weight: 400;
      text-align: center;
      color: var(--viridian-60);
      cursor: pointer;
    }
  }
}
